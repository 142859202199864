<template>
  <v-btn
    icon
    :small="small"
    color="#25D366"
    title="Enviar WhatsApp"
    :loading="load"
    @click="sendWpp"
  >
    <v-icon>fab fa-whatsapp</v-icon>
  </v-btn>
</template>

<script>
import { db } from '../../firebase'

export default {
  props: {
    numero: String,
    etapa: {
      type: String,
      default: '',
    },
    estado: {
      type: String,
      default: '',
    },
    default: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      load: false,
    }
  },
  methods: {
    async sendWpp () {
      if (this.default) {
        this.sendDefaultMessage()
      } else if (this.estado === 'Rechazado') {
        this.load = true
        await db.collection('mensajes_predefinidos').doc('whatsapp_rechazo').get()
          .then((doc) => {
            if (doc.exists) {
              this.enviar(doc.data().texto)
            }
          })
          .catch((error) => {
            console.log(error)
          })
        this.load = false
      } else {
        this.load = true
        await db.collection('etapas').where('nombre', '==', this.etapa).get()
          .then((res) => {
            let mensaje = ''
            res.forEach((doc) => {
              mensaje = doc.data().wpp
            })
            if (mensaje === '') {
              this.sendDefaultMessage()
            } else {
              this.enviar(mensaje)
            }
          })
          .catch((error) => {
            console.log(error)
          })
        this.load = false
      }
    },
    async sendDefaultMessage () {
      this.load = true
      await db.collection('mensajes_predefinidos').doc('whatsapp').get()
        .then((doc) => {
          if (doc.exists) {
            this.enviar(doc.data().texto)
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.load = false
    },
    enviar (mensaje) {
      window.open(`https://wa.me/549${this.numero}/?text=${mensaje}`, '_blank')
    }
  },
}
</script>