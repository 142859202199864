<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    :left="$vuetify.breakpoint.xs"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label=tipo
        prepend-icon="fas fa-calendar-day"
        readonly
        clearable
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      menu: false,
      date: '',
      dateFormatted: '',
    }
  },
  created() {
    this.cambiar()
  },
  props: {
    tipo: String,
  },
  watch: {
    filtro: {
      handler: function () {
        this.cambiar()
      },
      deep: true
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
      if (this.tipo === 'Desde') {
        this.$store.state.filtro.fecha.desde = this.date
        return
      }
      if (this.tipo === 'Hasta') {
        this.$store.state.filtro.fecha.hasta = this.date
      }
    },
  },
  computed: {
    ...mapState(['filtro'])
  },
  methods: {
    cambiar () {
      if (this.tipo === 'Desde') {
        this.date = this.filtro.fecha.desde
      } else {
        this.date = this.filtro.fecha.hasta
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>