<template>
  <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          style="cursor: pointer"
          @click="limpiar"
          v-bind="attrs"
          v-on="on"
        >
          fas fa-broom
        </v-icon>
      </template>
      <span>Limpiar</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    isConv: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['limpiar_filtro', 'set_filtro_conv', 'set_fecha_desde', 'set_fecha_hasta']),
    limpiar() {
      if (this.isConv) {
        this.set_filtro_conv({
          busqueda: '',
          empresa: '',
          area: '',
          puesto: '',
          fecha: {
            inicio: {
              desde: null,
              hasta: null,
            },
            fin: {
              desde: null,
              hasta: null,
            },
          },
          activa: true,
        })
      } else {
        this.limpiar_filtro()
      }
    }
  }
}
</script>