<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pb-1">
          <v-form ref="form" @submit.prevent="get_postulantes">
            <v-row>
                <v-col cols="6" md="2">
                  <v-select
                    v-model="filtro.provincia"
                    :items="nombre_provincias"
                    clearable
                    label="Provincia"
                    @change="get_departamentos"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="6" md="2">
                  <v-select
                    v-model.trim="filtro.departamento"
                    :items="departamentos"
                    clearable
                    label="Departamento"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model.trim="filtro.localidad"
                    label="Localidad"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex align-center justify-center">
                  <v-switch
                    v-model="filtro.sin_post"
                    label="Sin postularse"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="3" class="d-flex align-center justify-center">
                  <v-switch
                    v-model="filtro.insc_mas_1_conv"
                    label="Inscripto al menos en una búsqueda"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="5">
                  <v-row no-gutters>
                    <v-col cols="12" md="3" class="d-flex align-center body-1 font-weight-light">
                      <div v-if="!$vuetify.breakpoint.xs">Última modif. de CV</div>
                      <div v-else>Última modificación de CV</div>
                    </v-col>
                    <v-col>
                      <FechaFiltro tipo="Desde" />
                    </v-col>
                    <v-col class="ml-4">
                      <FechaFiltro tipo="Hasta" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model.trim="filtro.apellido"
                    label="Apellido/s"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    label="Nombre/s"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="d-flex align-center justify-start">
                  <v-switch
                    v-model="filtro.carnet"
                    label="Carnet de conducir"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model.trim="filtro.telefono"
                    v-mask="'###############'"
                    label="Teléfono"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="10" class="d-flex align-center justify-end">
                  <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon small right>fas fa-search</v-icon></v-btn>
                  <div class="ml-md-8 ml-2"><LimpiarFiltro /></div>
                </v-col>
              </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="postulantes"
      class="elevation-1 mt-2"
      :loading="load"
      :search="search"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-end pa-2" no-gutters>
          <v-col cols="6" sm="3">
            <v-text-field
              v-model="search"
              label="Buscar"
              hide-details
              single-line
              outlined
              dense
            >
              <template v-slot:append>
                <v-icon
                  class="mt-1"
                  title="Buscar en los datos filtrados"
                  small
                >
                  fas fa-search
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row no-gutters class="d-flex justify-center">
          <SendWpp :numero="item.telefono" :default="true" />
          <v-icon
            small
            color="info"
            title="Ver CV"
            @click="ver_postulante(item)"
          >
            fas fa-eye
          </v-icon>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
          :color="color"
        >
          {{ message }}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import FechaFiltro from '../../components/utils/FechaFiltro'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import SendWpp from '../../components/utils/SendWpp'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      color: '',
      search: '',
      message: 'No hay datos para los filtros seleccionados',
      postulantes: [],
      nombre_provincias: [],
      departamentos: [],
      headers: [
        { text: 'Apellido', value: 'apellido', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Teléfono', value: 'telefono', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
    }
  },
  created() {
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
  },
  components: {
    FechaFiltro, LimpiarFiltro, SendWpp
  },
  computed: {
    ...mapState(['filtro', 'provincias'])
  },
  methods: {
    async get_postulantes() {
      this.postulantes = []
      let query_size = 1
      let query = db.collection('usuarios').where('rol', '==', 'Postulante')

      if (!(this.filtro.provincia === null || this.filtro.provincia === '')) {
        query = query.where('cv.provincia', '==', this.filtro.provincia)
        if (!(this.filtro.departamento === null || this.filtro.departamento === '')) {
          query = query.where('cv.departamento', '==', this.filtro.departamento)
        }
      }

      if (this.filtro.fecha.desde !== null) query = query.where('cv.log.modificacion', '>=', this.filtro.fecha.desde)
      if (this.filtro.fecha.hasta !== null) query = query.where('cv.log.modificacion', '<=', this.filtro.fecha.hasta)
      if (this.filtro.carnet) query = query.where('cv.carnet_conducir', '==', true)
      if (this.filtro.telefono) query = query.where('cv.telefono.celular', '==', this.filtro.telefono)

      if (this.filtro.fecha.desde === null) {
        if (!this.filtro.telefono) {
          this.color = 'orange'
          this.message = 'Debe seleccionar una fecha Desde de Última modif. de CV o un teléfono'
          return 
        }
      }

      this.load = true
      this.color = ''
      this.message = 'No hay datos para los filtros seleccionados'

      await query.get()
        .then(async (res) => {
          query_size = res.size
          res.forEach(async (doc) => {
            let postulante = doc.data()
            postulante.id = doc.id
            postulante.telefono = postulante.cv.telefono.celular

            if (this.filtrar(postulante)) {
              await db.collection('usuarios_registrados').doc(postulante.id).get()
                .then(async (doc) => {
                  if (doc.exists) {
                    postulante.email = doc.data().email
                    this.postulantes.push(postulante)
                  }
                })
                .catch((error) => {
                  console.log(error)
                }) 
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
      await new Promise(resolve => setTimeout(resolve, 15 * query_size))
      this.load = false
    },
    filtrar(postulante) {
      const postulaciones = postulante.postulaciones.filter(post => post.estado !== 'Cancelada por el postulante')
      let pasa_filtro_sinpost = true
      let pasa_filtro_masdeuna = true

      if (this.filtro.sin_post) pasa_filtro_sinpost = postulaciones.length === 0
      if (this.filtro.insc_mas_1_conv) pasa_filtro_masdeuna = postulaciones.length >= 1

      const pasa_filtro_apellido = this.removeAccents(postulante.apellido).indexOf(this.removeAccents(this.filtro.apellido)) >= 0
      const pasa_filtro_nombre = this.removeAccents(postulante.nombre).indexOf(this.removeAccents(this.filtro.nombre)) >= 0
      const pasa_filtro_localidad = this.removeAccents(postulante.cv.localidad).indexOf(this.removeAccents(this.filtro.localidad)) >= 0

      return pasa_filtro_sinpost && pasa_filtro_masdeuna && pasa_filtro_apellido && pasa_filtro_nombre && pasa_filtro_localidad
    },
    ver_postulante (postulante) {
      let routeData = this.$router.resolve({path: `/admin/postulante/${postulante.id}/${postulante.email}`})
      window.open(routeData.href, '_blank')
    },
    get_departamentos () {
      this.departamentos = []
      if (this.filtro.provincia) {
        this.departamentos = this.provincias.find(prov => prov.nombre === this.filtro.provincia).departamentos
      }
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  },
}
</script>